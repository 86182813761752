import "styles/pages/shipping-info.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import ShippingInfoIcoCost from "assets/images/shipping-info-ico-cost.svg"
import ShippingInfoIcoTime from "assets/images/shipping-info-ico-time.svg"

const ShippingInfo = () => {
  const title = "Czas i koszty dostawy"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="shipping-info">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-xl-5">
              <PageSubHeader title="Przesyłka płatna z góry" />

              <div className="row">
                <div className="col-sm-6">
                  <strong>Przesyłka kurierska</strong>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoCost} alt="" />
                    </span>
                    Koszt: 16,00 zł
                  </div>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoTime} alt="" />
                    </span>
                    Czas realizacji: 1-2 dni robocze
                  </div>
                </div>

                <div className="col-sm-6">
                  <strong>Odbiór osobisty po przedpłacie</strong>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoCost} alt="" />
                    </span>
                    Koszt: 0,00 zł
                  </div>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoTime} alt="" />
                    </span>
                    Czas realizacji: 1 dzień roboczy
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-5 offset-xl-1">
              <PageSubHeader title="Przesyłka płatna przy odbiorze" />

              <div className="row">
                <div className="col-sm-6">
                  <strong>Przesyłka kurierska pobraniowa</strong>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoCost} alt="" />
                    </span>
                    Koszt: 24,00 zł
                  </div>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoTime} alt="" />
                    </span>
                    Czas realizacji: 1-2 dni robocze
                  </div>
                </div>

                <div className="col-sm-6">
                  <strong>Odbiór osobisty</strong>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoCost} alt="" />
                    </span>
                    Koszt: 0,00 zł
                  </div>
                  <div className="shipping-info__item">
                    <span>
                      <img src={ShippingInfoIcoTime} alt="" />
                    </span>
                    Czas realizacji: 1 dzień roboczy
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ShippingInfo
